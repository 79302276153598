<div class="container">

  <br>
  <br>

  <h2>Privacy Policy</h2>
  <br />
  <p>
    We know that you care how your information is used, and we appreciate your
    trust that we will use it carefully and sensibly. This notice describes our
    privacy policy. By visiting us, you are accepting the privacy policy
    described below.
  </p>
  <br />

  <p>1. What Personal Information Do We Collect?</p>
  <br />
  <p>
    Information You Provide. We receive and store information you enter. For
    example, when you search for or buy a product or service, or when you supply
    information such as your address, phone number or credit card. You can
    choose not to provide certain information, but then you might not be able to
    take advantage of some of our features. We use the information that you
    provide for such purposes as responding to your requests, customizing future
    shopping for you, improving our website, and communicating with you. In
    addition, if you choose, we may share your information with other companies
    who provide goods or services that you are interested in. Please see the
    “opt-in/opt-out” selection in Section 4 of this policy.
  </p>

  <p>
    Cookies. Like many websites, we use “cookies”. Cookies are small programs
    that we transfer to your hard drive that allow us to recognize you and to
    provide you with a customized shopping experience. If you do not want us to
    use cookies, you can easily disable them by going to the toolbar of your web
    browser, and clicking on the “help” button. Follow the instructions that
    will prevent the browser from accepting cookies, or set the browser to
    inform you when you receive a new cookie. In addition, you may visit this
    and other websites anonymously through the use of utilities provided by
    other private companies.
  </p>

  <p>
    Other Information: Every computer has an IP (Internet Protocol) address. IP
    addresses of computers used to visit this site are noted. In addition, we
    automatically collect other information such as email addresses, browser
    types, operating systems, and the URL addresses of sites clicked to and from
    this site.
  </p>

  <p>
    Information from Other Sources. We might receive information about you from
    other sources and add it to our account information. This may include
    updated delivery and address information from our shippers or other sources
    so that we can correct our records and deliver your next purchase or
    communication more easily.
  </p>

  <br />
  <p>2. How Do We Use Your Information?</p>
  <br />
  <p>
    Customized Shopping. We use your information to better serve you by
    providing a customized shopping experience. As noted above, you may
    “opt-out” of this customization, or even visit and shop anonymously.
  </p>

  <p>
    Agents. We employ other companies and individuals to perform functions on
    our behalf. Examples include delivering packages, sending postal mail and
    e-mail, and processing credit card payments. They have access to personal
    information needed to perform their functions, but may not use it for other
    purposes.
  </p>

  <p>
    Special Offers. We may send you special offers from time to time, unless you
    choose to “opt-out” of receiving such offers. In addition, we may also send
    you special offers from other companies. Again, you may “opt-out”. If we
    send you offers from other businesses, we do not share your personal
    information with them. The offers come directly through us. Please see the
    “opt-in/opt-out” selection at the end of this policy.
  </p>

  <p>
    Business Transfers. As we continue to develop our business, we might sell or
    buy stores or assets. In such transactions, customer information generally
    is one of the transferred business assets. Also, in the unlikely event that
    we are acquired, customer information will be one of the transferred assets.
  </p>

  <p>
    Law Enforcement. If we receive a lawful court order to release account or
    other personal information then we will comply with the law. We will also
    release information when necessary to protect the life, safety or property
    of others. This includes exchanging information with other companies and
    organizations for fraud protection and credit risk reduction.
  </p>

  <br />
  <p>3. How Do We Protect the Security of Your Information?</p>
  <br />
  <p>
    We work to protect the security of your information during transmission by
    using Secure Sockets Layer (SSL) software, which encrypts information you
    input. We use encryption to protect your information contained in our
    customer list. We use a firewall to protect against unlawful intrusion. We
    limit access to your information on a “need to know” basis. We reveal only
    the last five digits of your credit card numbers when confirming an order.
    Of course, we transmit the entire credit card number to the appropriate
    credit card company during order processing.
  </p>
  <p>
    It is important for you to protect against unauthorized access to your
    password and to your computer. Be sure to sign off when finished using a
    shared computer. No system can guarantee absolute security, just as the
    finest lock can not guarantee physical security. However, we take every
    reasonable precaution to assure that your data is secure.
  </p>

  <br />
  <p>4. Your Choice: Opt-in or Opt-out.</p>
  <br />

  <p>
    It is your choice whether to receive emails or special offers from us or
    others. The following section provides you with this choice. Please note the
    default settings. Please send me email or other mail informing me of special
    offers of your products or services. Please do not send me email or other
    mail informing me of special offers of your products or services. Please
    send me email or other mail informing me of special offers of related
    products or services from other companies. Please do not send me email or
    other mail informing me of special offers of related products or services
    from other companies.
  </p>

  <br />
  <p>5. Children</p>
  <br />

  <p>
    We do not sell products or services to children. If you are under 18, you
    may use this site only with involvement of a parent or guardian
  </p>

  <br />
  <p>6. Other Websites</p>
  <br />

  <p>
    Various Web sites may be linked to from this site. If you link to another
    site, your privacy depends on the policy of that site. We strongly urge you
    to check their privacy policy. Not all sites guarantee that they will not
    share your personally identifiable information with others. You may also
    wish to consult privacy guidelines such as those recommended by the Online
    Privacy Alliance (www.privacyalliance.org).
  </p>

  <br />
  <p>7. Contact Us</p>
  <br />

  <p>
    If you would like to learn more about our privacy policy, or to access your
    personally identifiable information contained on our website, you may
    contact us at info [at] crazymuscle.com. You will be required to provide
    identifier information to assure that this information is not released to
    others. We reserve the right to modify this policy in the future. If we do
    so, notice will be posted on our home page.
  </p>

  <br />
  <p>8. Terms of Use</p>
  <br />

  <p>
    Use of this site, placing an order online from this site, mailing in an
    order to this site or phoning in an order to this site signifies your
    acceptance to the terms and polices outlined below.
  </p>

  <br />
  <p>9. Site Disclaimer</p>
  <br />

  <p>
    Product descriptions, training articles and all other forms of information
    on this site or print material produced by this site should be treated as
    informational only and are not to be taken as professional or medical
    advice. You should seek the advice of your physician or a medical
    professional when starting any diet or training program. Product
    descriptions are taken from the manufacturers labels or manufacturers
    advertising material.
  </p>

  <p>
    MintieJamie is not responsible for any statements or claims made by any
    manufacturer about their products. Every attempt is made to keep product
    descriptions up to date but we are not responsible for typographical errors
    or product formulation changes. Please report all discrepancies to us via
    our contact page.
  </p>

  <p>All sales are final, unless conditions are met for a refund.</p>
</div>
