<main class="main0">
    <molla-page-header title="Elements List" subtitle="Elements1"></molla-page-header>

    <nav class="breadcrumb-nav">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a routerLink="/">Home</a>
                </li>
                <li class="breadcrumb-item">
                    <a routerLink="/elements">Elements</a>
                </li>
            </ol>
        </div>
    </nav>
</main>