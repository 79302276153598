export const environment = {
  adminUid: "DOXLLLE4zyQEnndmiB2WyHjKVwB2",
  email: "evelee.lse@gmail.com",
  production: false,
  demo: 'demo11',
  apiKey: "AIzaSyCDjvfxZwbOMjMv2dysbQEsm-Z2VYVfjH8",
  authDomain: "mintiejamie-f0fe3.firebaseapp.com",
  projectId: "mintiejamie-f0fe3",
  storageBucket: "mintiejamie-f0fe3.appspot.com",
  messagingSenderId: "108262154428",
  appId: "1:108262154428:web:fa94295565045550d730b9",
  measurementId: "G-RJBSR946WW",
  SERVER_URL: 'https://strapi-opkdvvnhaq-uc.a.run.app/',
  STRIPE_SERVER_URL: 'https://server-opkdvvnhaq-uc.a.run.app/',
  STRIPE_PUBLIC_KEY: 'pk_test_51Khbb7LWcBaZPN5YoIm8aOIiPrs0EqCwSTqx4pw6Qh7VneAdz6mIN31AlsVu1fB1m6jaCOvvZ8uxRO5aetQgh1sK00PVQ9L461'
};
