<br>
<br>

<div class="modal-body">
  <div class="form-box">
    <div class="form-tab nav-fill">
      <form [formGroup]="form" (ngSubmit)="forgetPassword()">
        <!-- <form (ngSubmit)="forgetPassword()"> -->
        <div class="form-group">
          <label for="forgetPasswordEmail">Your email address *</label>
          <input
            type="email"
            formControlName="email"
            ngModel
            class="form-control"
            id="forgetPasswordEmail"
            name="forgetPasswordEmail"
            required
          />
          <div *ngIf="email && email.invalid && email.touched">
            Please enter a valid email.
          </div>
        </div>
        <div class="form-footer">
          <button type="submit" class="btn btn-outline-primary-2">
            <span>RESET PASSWORD</span>
            <i class="icon-long-arrow-right"></i>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<br>
<br>
<br>