<footer class="footer footer-2">
    <div class="footer-bottom">
        <div [ngClass]="containerClass">
            <p class="footer-copyright">Copyright © {{ year }} MintieJamie. All Rights Reserved.</p>
            <ul class="footer-menu">
                <li><a routerLink="terms">Terms Of Use</a></li>
                <li><a routerLink="privacyPolicy">Privacy Policy</a></li>
            </ul>

            <div class="social-icons social-icons-color">
                
                <a href="https://www.amazon.com/mintiejamie" class="social-icon social-amazon" title="Amazon"><i
                        class="icon-amazon"></i></a>
                <a href="https://www.instagram.com/mintiejamiee/" target="_blank" class="social-icon social-instagram" title="Instagram"><i
                        class="icon-instagram"></i></a>
                <a href="mailto:roomfor3three@gmail.com?subject=Product Enquiry" target="_blank" class="social-icon social-mail" title="Mail"><i
                        class="icon-envelope"></i></a>
            </div>
        </div>
    </div>

    <div class="mb-10" *ngIf="isBottomSticky"></div>
</footer>