<div class="mobile-menu-container">
  <div class="mobile-menu-wrapper">
    <span class="mobile-menu-close" (click)="hideMobileMenu()"><i class="icon-close"></i></span>

    <form action="#" method="get" class="mobile-search" (submit)="submitSearchForm($event)">
      <label for="mobile-search" class="sr-only">Search</label>
      <input type="search" class="form-control" name="mobile-search" id="mobile-search" placeholder="Search in..."
             required>
      <button class="btn btn-primary" type="submit"><i class="icon-search"></i></button>
    </form>

    <nav class="mobile-nav">
      <ul class="mobile-menu">
        <li>
          <a (click)="hideMobileMenu()" routerLink="/">
            Home
          </a>

        </li>
        <li>
          <a (click)="hideMobileMenu()" routerLink="/shop/sidebar/3cols">
            Shop
          </a>

        </li>

        <li>
          <a  (click)="hideMobileMenu()" routerLink="/pages/about">About Us</a>
        </li>
        <li>
          <a  (click)="hideMobileMenu()" routerLink="/pages/contact">Contact Us</a>
        </li>
        <li><a routerLink="/pages/faq">FAQs</a></li>

        <!-- <li *ngIf="!this.firebaseService.isLoggedIn" >
          <a (click)="hideMobileMenu()" routerLink="/auth/login">Profile</a>
        </li>

        <li *ngIf="this.firebaseService.isLoggedIn">
          <a (click)="hideMobileMenu()" routerLink="/auth/profile">Profile</a>
        </li> -->
      </ul>
    </nav>

    <div class="social-icons">
      <a href="javascript:;" class="social-icon" title="Facebook"><i class="icon-facebook-f"></i></a>
      <a href="javascript:;" class="social-icon" title="Instagram"><i class="icon-instagram"></i></a>
    </div>
  </div>
</div>
