<nav class="main-nav" >
  <ul class="menu sf-arrows">
    <li class="megamenu-container" [class.active]="current=='/' || current.startsWith('/?')">
      <a routerLink="/"  class="sf-without-ul">Home</a>
    </li>

    <li [class.active]="current.includes('/shop')">
      <a routerLink="/shop/sidebar/3cols" class="sf-without-ul" >Shop</a>
    </li>


    <li [class.active]="current.includes('pages/about')">
      <a routerLink="/pages/about"  class="sf-without-ul">About Us</a>
    </li>
    <li [class.active]="current.includes('pages/contact')">
      <a routerLink="/pages/contact"  class="sf-without-ul">Contact Us</a>
    </li>
    <li [class.active]="current.includes('pages/faq')">
      <a routerLink="/pages/faq"  class="sf-without-ul">FAQs</a>
    </li>



    <!-- auth guard -->
    <!-- <li *ngIf="!this.firebaseService.isLoggedIn"[class.active]="current.includes('login')">
      <a routerLink="/auth/login" class="sf-without-ul">SIGN IN | REGISTER</a>
    </li>

    <li *ngIf="this.firebaseService.isLoggedIn" [class.active]="current.includes('profile')">
      <a routerLink="/auth/profile" class="sf-without-ul">Dashboard</a>
    </li> -->
  </ul>
</nav>
