<div class="modal-body">


  <div class="form-box">
    <div class="form-tab nav-fill">
      <nav>
      <ul ngbNav #nav="ngbNav" class="justify-content-center nav nav-pills">
        <li ngbNavItem>
          <a ngbNavLink>Sign In</a>
          <ng-template ngbNavContent>
            <form [formGroup]="form2" (ngSubmit)="login()">

              <div class="form-group">
                <label for="singin-email">Email address *</label>
                <input type="email" formControlName="email2" ngModel class="form-control" id="singin-email" name="singin-email" required>
                <div *ngIf="email2 && email2.invalid && email2.touched" style="color: red;">
                  Please enter a valid email.
                </div>
              </div>

              <div class="form-group">
                <label for="singin-password">Password *</label>
                <input type="password" formControlName="password2" ngModel class="form-control" id="singin-password" name="singin-password"
                       required>
                       <div *ngIf="password2 && password2.invalid && password2.touched" style="color: red;">
                        Password must be 6 characters long.
                      </div>
              </div>

              <div class="form-footer">
                <button type="submit" class="btn btn-outline-primary-2">
                  <span>LOG IN</span>
                  <i class="icon-long-arrow-right"></i>
                </button>

              </div>
            </form>
            <a class="forgot-link" routerLink='/auth/forgetPassword'>Forgot Your Password?</a>
          </ng-template>
        </li>
        <li ngbNavItem>
          <a ngbNavLink>Register</a>
          <ng-template ngbNavContent>
            <form [formGroup]="form" (ngSubmit)="register()">
              <!-- First Name and Last Name -->

              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="first-name">First Name *</label>
                      <input type="text" formControlName="firstName" ngModel class="form-control" id="firstName" name="firstName" required>
                      <div *ngIf="firstName && firstName.invalid && firstName.touched" style="color: red;">
                        Please enter your first name.
                      </div>
                  </div>
                </div>

                <div class="col">
                <div class="form-group">
                  <label for="last-name">Last Name *</label>
                    <input type="text" formControlName="lastName" ngModel class="form-control" id="lastName" name="lastName" required>
                    <div *ngIf="lastName && lastName.invalid && lastName.touched" style="color: red;">
                      Please enter your last name.
                    </div>
                </div>
              </div>
            </div>
              <div class="form-group">
                <label for="registerEmail">Your email address *</label>
                <input type="email" formControlName="email" ngModel class="form-control" id="registerEmail" name="registerEmail"
                       required>
                <div *ngIf="email && email.invalid && email.touched" style="color: red;">
                  Please enter a valid email.
                </div>
              </div>

              <div class="form-group">
                <label for="registerPassword">Password *</label>
                <input type="password" formControlName="password" ngModel class="form-control" id="registerPassword"
                       name="registerPassword" required>
                <div *ngIf="password && password.invalid && password.touched" style="color: red;">
                  Password must be 6 characters long.
                </div>
              </div>

              <div class="form-footer">
                <button type="submit" class="btn btn-outline-primary-2">
                  <span>SIGN UP</span>
                  <i class="icon-long-arrow-right"></i>
                </button>
              </div>
            </form>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav"></div>
    </nav>
    </div>


  </div>
</div>
