import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'pages-coming-soon',
	templateUrl: './coming-soon.component.html',
	styleUrls: ['./coming-soon.component.scss']
})

export class ComingSoonPageComponent implements OnInit {

	constructor() {

	}

	ngOnInit(): void {
	}
}
