<router-outlet></router-outlet>
<div class="container mb-5">
    <div class="row elements">
        <div class="col-xl-5col col-lg-4 col-md-3 col-6" *ngFor="let item of items; let i = index;">
            <a [routerLink]="[item.link]" class="element-type">
                <div class="element">
                    <i class="element-img" style="background-image: url(assets/images/elements.png);"></i>
                    <i class="element-hover-img" style="background-image: url(assets/images/elements.png);"></i>
                    <p>{{ item.name }}</p>
                </div>
            </a>
        </div>
    </div>
</div>