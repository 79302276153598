import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'molla-payment-cancel',
  templateUrl: './payment-cancel.component.html',
  styleUrls: ['./payment-cancel.component.scss']
})
export class PaymentCancelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
