

<div class="container">
    <br>
    <br>

  <h2>Terms & Conditions</h2>

  <p>
    Welcome to www.mintiejamie.com. If you continue to browse and use this
    website you are agreeing to comply with and be bound by the following terms
    and conditions of use, which together with our privacy policy govern
    mintiejamie’s relationship with you in relation to this website. The term
    ‘MintieJamie’ or ‘us’ or ‘we’ refers to the owner of the website. The term
    ‘you’ refers to the user or viewer of our website. The use of this website
    is subject to the following terms of use:
  </p>

  <br>

  <ul>
    <li>
      The content of the pages of this website is for your general information
      and use only. It is subject to change without notice.
    </li>
    <li>
      Neither we nor any third parties provide any warranty or guarantee as to
      the accuracy, timeliness, performance, completeness or suitability of the
      information and materials found or offered on this website for any
      particular purpose. You acknowledge that such information and materials
      may contain inaccuracies or errors and we expressly exclude liability for
      any such inaccuracies or errors to the fullest extent permitted by law.
    </li>
    <li>
      Orders placed on this website will be issued a tracking number through
      USPS. It is the responsibility of the customer to require signature upon
      delivery if there is any possibility of package theft if left unattended.
    </li>
    <li>
      International customers take 100% full responsibility in the event that
      the package gets lost, stopped at customs or subject to additional
      tariff/duty charges. In the case of a customs seizure, a refund will ONLY
      be given if the products are returned to us less any shipping costs. For
      lost packages that have Express postage, we will file a lost items claim
      and refund you the total minus shipping as soon as we are reimbursed
      through the postal service.
    </li>
    <li>
      Domestic customers agree to a forfeiture of any chargebacks if we are able
      to prove that all items were shipped with a valid USPS tracking number and
      that the package(s) were delivered.
    </li>
    <li>
      A refund will be given if and only if an order was shipped incorrectly or
      is returned by the customer unopened within 30 days of purchase. By
      purchasing from mintiejamie.com, you agree to these conditions.
    </li>
    <li>
      Your use of any information or materials on this website is entirely at
      your own risk, for which we shall not be liable. It shall be your own
      responsibility to ensure that any products, services or information
      available through this website meet your specific requirements.
    </li>
    <li>
      This website contains material which is owned by or licensed to us. This
      material includes, but is not limited to, the design, layout, look,
      appearance and graphics. Reproduction is prohibited other than in
      accordance with the copyright notice, which forms part of these terms and
      conditions.
    </li>
    <li>
      All trademarks reproduced in this website, which are not the property of,
      or licensed to the operator, are acknowledged on the website.
    </li>
    <li>
      Unauthorized use of this website may give rise to a claim for damages
      and/or be a criminal offence.
    </li>
    <li>
      From time to time this website may also include links to other websites.
      These links are provided for your convenience to provide further
      information. They do not signify that we endorse the website(s). We have
      no responsibility for the content of the linked website(s).
    </li>
    <li>
      Your use of this website and any dispute arising out of such use of the
      website is subject to the laws of the United States of America.
    </li>
  </ul>
</div>

<br>
<br>