<main class="main">
    <div class="container">
        <div class="page-header page-header-big text-center"
            style="background-image: url('assets/images/contact-header-bg.jpg')">
            <h1 class="page-title text-white">Contact us<span class="text-white">keep in touch with us</span></h1>
        </div>
    </div>

    <div class="page-content pb-0">
        <div class="container">
            <div class="row">

                <div class="col-lg-6">
                    <h2 class="title mb-1">Get In Touch With Us</h2>
                    <p class="mb-2">We welcome your feedback, suggestions and enquiries. Kindly fill in the following contact form and we will be in touch with you soon. Thank you!</p>

                    <form [formGroup]="form" (ngSubmit)="contactUs(form.value)" class="contact-form mb-3">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                <label for="cname" class="sr-only">Name</label>
                                <input type="text" formControlName="name" ngModel class="form-control" id="cname" placeholder="Name *" required>
                                <div *ngIf="name && name.invalid && name.touched" style="color: red;">
                                    Please enter name.
                                  </div>
                            </div>
                        </div>


                            <div class="col-sm-6">
                                <div class="form-group">
                                <label for="cemail" class="sr-only">Email</label>
                                <input type="email" formControlName="email" ngModel class="form-control" id="cemail" placeholder="Email *" required>
                                <div *ngIf="email && email.invalid && email.touched" style="color: red;">
                                    Please enter email.
                                  </div>
                            </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                <label for="cphone" class="sr-only">Phone</label>
                                <input type="tel" formControlName="phone" ngModel class="form-control" id="cphone" placeholder="Phone">
                            </div>
                        </div>

                            <div class="col-sm-6">
                                <div class="form-group">
                                <label for="csubject" class="sr-only">Subject</label>
                                <input type="text" formControlName="subject" ngModel class="form-control" id="csubject" placeholder="Subject *">
                                <div *ngIf="subject && subject.invalid && subject.touched" style="color: red;">
                                    Please enter subject.
                                  </div>
                            </div>
                            </div>
                        </div>

                        <div class="form-group">
                        <label for="cmessage" class="sr-only">Message</label>
                        <textarea formControlName="message" ngModel class="form-control" cols="30" rows="4" id="cmessage" required
                            placeholder="Message *"></textarea>
                            <div *ngIf="message && message.invalid && message.touched" style="color: red;">
                                Please enter message.
                              </div>
                        </div>

                        <button type="submit" class="btn btn-outline-primary-2 btn-minwidth-sm">
                            <span>SUBMIT</span>
                            <i class="icon-long-arrow-right"></i>
                        </button>
                    </form>
                </div>
                
                <div class="col-lg-6 mb-2 mb-lg-0">
                    <h2 class="title mb-1">Contact Information</h2>
                    <p class="mb-3">Feel free to drop us an email directly or drop your enquiry at the side! :P</p>
                    <div class="row">
                        <div class="col-sm-7">
                            <div class="contact-info">
                                <h3>Email</h3>

                                <ul class="contact-list">
                                    <!-- <li>
                                        <i class="icon-map-marker"></i>
                                        70 Washington Square South New York, NY 10012, United States
                                    </li>
                                    <li>
                                        <i class="icon-phone"></i>
                                        <a href="tel:#">+92 423 567</a>
                                    </li> -->
                                    <li>
                                        <i class="icon-envelope"></i>
                                        <a href="mailto:#">roomfor3@gmail.com</a>
                                    </li>

                                    <h3>Shop</h3>
                                               
                                    <li>
                                        <i class="icon-amazon"></i>
                                        <a href="https://www.amazon.com/mintiejamie" target="_blank">MintieJamie</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <!-- <div class="col-sm-5">
                            <div class="contact-info">
                                <h3>The Office</h3>

                                <ul class="contact-list">
                                    <li>
                                        <i class="icon-clock-o"></i>
                                        <span class="text-dark">Monday-Saturday</span> <br>11am-7pm ET
                                    </li>
                                    <li>
                                        <i class="icon-calendar"></i>
                                        <span class="text-dark">Sunday</span> <br>11am-6pm ET
                                    </li>
                                </ul>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>

            <!-- <hr class="mt-4 mb-5"> -->

            <!-- <div class="stores mb-4 mb-lg-5">
                <h2 class="title text-center mb-3">Our Stores</h2>

                <div class="row text-center">
                    <div class="col-lg-6">
                        <div class="store">
                            <div class="row">
                                <div class="col-sm-5 col-xl-6">
                                    <figure class="store-media mb-2 mb-lg-0">
                                        <img src="assets/images/stores/img-1.jpg" class="ml-auto mr-auto ml-sm-0"
                                            width="277" height="277" alt="image" />
                                    </figure>
                                </div>
                                <div class="col-sm-7 col-xl-6">
                                    <div class="store-content">
                                        <h3 class="store-title">Wall Street Plaza</h3>
                                        <address>88 Pine St, New York, NY 10005, USA</address>
                                        <div><a href="tel:#">+1 987-876-6543</a></div>

                                        <h4 class="store-subtitle">Store Hours:</h4>
                                        <div>Monday - Saturday 11am to 7pm</div>
                                        <div>Sunday 11am to 6pm</div>

                                        <a href="javascript:;" class="btn btn-link"><span>View
                                                Map</span><i class="icon-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="store">
                            <div class="row">
                                <div class="col-sm-5 col-xl-6">
                                    <figure class="store-media mb-2 mb-lg-0">
                                        <img src="assets/images/stores/img-2.jpg" class="ml-auto mr-auto ml-sm-0"
                                            width="277" height="277" alt="image" />
                                    </figure>
                                </div>

                                <div class="col-sm-7 col-xl-6">
                                    <div class="store-content">
                                        <h3 class="store-title">One New York Plaza</h3>
                                        <address>88 Pine St, New York, NY 10005, USA</address>
                                        <div><a href="tel:#">+1 987-876-6543</a></div>

                                        <h4 class="store-subtitle">Store Hours:</h4>
                                        <div>Monday - Friday 9am to 8pm</div>
                                        <div>Saturday - 9am to 2pm</div>
                                        <div>Sunday - Closed</div>

                                        <a href="javascript:;" class="btn btn-link"><span>View
                                                Map</span><i class="icon-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>

        <!-- <div id="map">
            <div *ngIf="apiLoaded | async">
                <google-map></google-map>
            </div>
        </div> -->
    </div>
</main>