<main class="main">
  <nav class="breadcrumb-nav border-0 mb-0">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/">Home</a>
        </li>
        <li class="breadcrumb-item">
          <a href="javascript:;">Pages</a>
        </li>
        <li class="breadcrumb-item active">Payment Cancel</li>
      </ol>
    </div>
  </nav>


  <div class="error-content text-center position-relative"
       style="background-image: url(assets/images/backgrounds/error-bg.jpg); margin-bottom: -1px;">
    <div class="container">
      <h1 class="error-title">Payment Cancel</h1>
      <p>Payment cancelled. Visit us soon </p>
      <a routerLink="/" class="btn btn-outline-primary-2 btn-minwidth-lg">
        <span>BACK TO HOMEPAGE</span>
        <i class="icon-long-arrow-right"></i>
      </a>
    </div>
  </div>
</main>
