<div class="main">
    <molla-page-header [title]="'Typography & Text Grid'" [subtitle]="'Elements'"></molla-page-header>

    <nav class="breadcrumb-nav">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a routerLink="/">Home</a>
                </li>
                <li class="breadcrumb-item">
                    <a routerLink="/elements">Elements</a>
                </li>
                <li class="breadcrumb-item active">Typography & Text Grid</li>
            </ol>
        </div>
    </nav>

    <div class="page-content">
        <div class="container">
            <h1>Heading H1</h1>
            <p>Morbi purus libero, faucibus adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent elementum
                hendrerit tortor. Sed semper lorem at felis. Vestibulum volutpat, lacus a ultrices sagittis, mi neque
                euismod dui, eu pulvinar nunc sapien ornare nisl. Phasellus pede arcu, dapibus eu, fermentum et, dapibus
                sed, urna.</p>

            <hr>
            <h2>Heading H2</h2>
            <p>Morbi purus libero, faucibus adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent elementum
                hendrerit tortor. Sed semper lorem at felis. Vestibulum volutpat, lacus a ultrices sagittis, mi neque
                euismod dui, eu pulvinar nunc sapien ornare nisl. Phasellus pede arcu, dapibus eu, fermentum et, dapibus
                sed, urna.</p>

            <hr>
            <h3>Heading H3</h3>
            <p>Morbi purus libero, faucibus adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent elementum
                hendrerit tortor. Sed semper lorem at felis. Vestibulum volutpat, lacus a ultrices sagittis, mi neque
                euismod dui, eu pulvinar nunc sapien ornare nisl. Phasellus pede arcu, dapibus eu, fermentum et, dapibus
                sed, urna.</p>

            <hr>
            <h4>Heading H4</h4>
            <p>Morbi purus libero, faucibus adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent elementum
                hendrerit tortor. Sed semper lorem at felis. Vestibulum volutpat, lacus a ultrices sagittis, mi neque
                euismod dui, eu pulvinar nunc sapien ornare nisl. Phasellus pede arcu, dapibus eu, fermentum et, dapibus
                sed, urna.</p>

            <hr>
            <h5>Heading H5</h5>
            <p>Morbi purus libero, faucibus adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent elementum
                hendrerit tortor. Sed semper lorem at felis. Vestibulum volutpat, lacus a ultrices sagittis, mi neque
                euismod dui, eu pulvinar nunc sapien ornare nisl. Phasellus pede arcu, dapibus eu, fermentum et, dapibus
                sed, urna.</p>

            <hr>
            <h6>Heading H6</h6>
            <p>Morbi purus libero, faucibus adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent elementum
                hendrerit tortor. Sed semper lorem at felis. Vestibulum volutpat, lacus a ultrices sagittis, mi neque
                euismod dui, eu pulvinar nunc sapien ornare nisl. Phasellus pede arcu, dapibus eu, fermentum et, dapibus
                sed, urna.</p>

            <hr>
            <h4>Full Width</h4>
            <p>Morbi purus libero, faucibus adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent elementum
                hendrerit tortor. Sed semper lorem at felis. Vestibulum volutpat, lacus a ultrices sagittis, mi neque
                euismod dui, eu pulvinar nunc sapien ornare nisl. Phasellus pede arcu, dapibus eu, fermentum et, dapibus
                sed, urna.</p>

            <hr>
            <h4>One Half</h4>
            <div class="row">
                <div class="col-md-6">
                    <p>Morbi purus libero, faucibus adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent
                        elementum hendrerit tortor. Sed semper lorem at felis. Vestibulum volutpat, lacus a ultrices
                        sagittis, mi neque euismod dui, eu pulvinar nunc sapien ornare nisl. Phasellus pede arcu,
                        dapibus eu, fermentum et, dapibus sed, urna.</p>
                </div>

                <div class="col-md-6">
                    <p>Morbi purus libero, faucibus adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent
                        elementum hendrerit tortor. Sed semper lorem at felis. Vestibulum volutpat, lacus a ultrices
                        sagittis, mi neque euismod dui, eu pulvinar nunc sapien ornare nisl. Phasellus pede arcu,
                        dapibus eu, fermentum et, dapibus sed, urna.</p>
                </div>
            </div>

            <hr>
            <h4>One Third</h4>
            <div class="row">
                <div class="col-md-4">
                    <p>Morbi purus libero, faucibus adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent
                        elementum hendrerit tortor. Sed semper lorem at felis. Vestibulum volutpat, lacus a ultrices
                        sagittis, mi neque euismod </p>
                </div>

                <div class="col-md-4">
                    <p>Morbi purus libero, faucibus adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent
                        elementum hendrerit tortor. Sed semper lorem at felis. Vestibulum volutpat, lacus a ultrices
                        sagittis, mi neque euismod </p>
                </div>

                <div class="col-md-4">
                    <p>Morbi purus libero, faucibus adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent
                        elementum hendrerit tortor. Sed semper lorem at felis. Vestibulum volutpat, lacus a ultrices
                        sagittis, mi neque euismod </p>
                </div>
            </div>

            <hr>
            <h4>One Fourth</h4>
            <div class="row">
                <div class="col-md-3">
                    <p>Morbi purus libero, faucibus adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent
                        elementum hendrerit tortor. Sed semper lorem at felis. </p>
                </div>

                <div class="col-md-3">
                    <p>Morbi purus libero, faucibus adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent
                        elementum hendrerit tortor. Sed semper lorem at felis. </p>
                </div>

                <div class="col-md-3">
                    <p>Morbi purus libero, faucibus adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent
                        elementum hendrerit tortor. Sed semper lorem at felis. </p>
                </div>

                <div class="col-md-3">
                    <p>Morbi purus libero, faucibus adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent
                        elementum hendrerit tortor. Sed semper lorem at felis. </p>
                </div>
            </div>
        </div>
    </div>
</div>