<main class="main">

  <div class="error-content text-center position-relative"
       style="background-image: url(assets/images/backgrounds/error-bg.jpg); margin-bottom: -1px;">
    <div class="container">
      <h1 class="error-title">Payment Success</h1>
      <p>Thanks for your support. We will arrange your delivery asap!</p>
      <a routerLink="/" class="btn btn-outline-primary-2 btn-minwidth-lg">
        <span>BACK TO HOMEPAGE</span>
        <i class="icon-long-arrow-right"></i>
      </a>
    </div>
  </div>
</main>
