<div [class]="wrap ? wrap : 'product-countdown is-countdown'">
    <span class="countdown-row countdown-show4" *ngIf="format !== 'HMS' && labelsShort">
        <span class="countdown-section">
            <span class="countdown-amount">{{ 10 > days ? '0' + days : days }}</span>
            <span class="countdown-period">Days</span>
        </span>
        <span class="countdown-section">
            <span class="countdown-amount">{{ 10 > hours ? '0' + hours : hours }}</span>
            <span class="countdown-period">Hours</span>
        </span>
        <span class="countdown-section">
            <span class="countdown-amount">{{ 10 > minutes ? '0' + minutes : minutes }}</span>
            <span class="countdown-period">Mins</span>
        </span>
        <span class="countdown-section">
            <span class="countdown-amount">{{ 10 > seconds ? '0' + seconds : seconds }}</span>
            <span class="countdown-period">Secs</span>
        </span>
    </span>
    <span class="countdown-row countdown-show4" *ngIf="format !== 'HMS' && ! labelsShort && ! compact">
        <span class="countdown-section">
            <span class="countdown-amount">{{ 10 > days ? '0' + days : days }}</span>
            <span class="countdown-period">Days</span>
        </span>
        <span class="countdown-section">
            <span class="countdown-amount">{{ 10 > hours ? '0' + hours : hours }}</span>
            <span class="countdown-period">Hours</span>
        </span>
        <span class="countdown-section">
            <span class="countdown-amount">{{ 10 > minutes ? '0' + minutes : minutes }}</span>
            <span class="countdown-period">Minutes</span>
        </span>
        <span class="countdown-section">
            <span class="countdown-amount">{{ 10 > seconds ? '0' + seconds : seconds }}</span>
            <span class="countdown-period">Seconds</span>
        </span>
    </span>
    <span class="countdown-row countdown-amount" *ngIf="format !== 'HMS' && ! labelsShort && compact">
        {{ days + ' DAYS, ' + ( 10 > hours ? '0' : '' ) + hours + ': ' + ( 10 > minutes ? '0' : '' ) + minutes + ': ' + ( 10 > seconds ? '0' : '' ) + seconds }}
    </span>

    <span class="countdown-row countdown-show3" *ngIf="format === 'HMS' && ! labelsShort">
        <span class="countdown-section">
            <span class="countdown-amount">{{ 10 > hours ? '0' + hours : hours }}</span>
            <span class="countdown-period">Hours</span>
        </span>
        <span class="countdown-section">
            <span class="countdown-amount">{{ 10 > minutes ? '0' + minutes : minutes }}</span>
            <span class="countdown-period">Minutes</span>
        </span>
        <span class="countdown-section">
            <span class="countdown-amount">{{ 10 > seconds ? '0' + seconds : seconds }}</span>
            <span class="countdown-period">Seconds</span>
        </span>
    </span>

    <span class="countdown-row countdown-show3" *ngIf="format === 'HMS' && labelsShort">
        <span class="countdown-section">
            <span class="countdown-amount">{{ 10 > hours ? '0' + hours : hours }}</span>
            <span class="countdown-period">hrs</span>
        </span>
        <span class="countdown-section">
            <span class="countdown-amount">{{ 10 > minutes ? '0' + minutes : minutes }}</span>
            <span class="countdown-period">mins</span>
        </span>
        <span class="countdown-section">
            <span class="countdown-amount">{{ 10 > seconds ? '0' + seconds : seconds }}</span>
            <span class="countdown-period">secs</span>
        </span>
    </span>
</div>