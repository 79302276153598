<header class="header header-7 position-relative">
    <div class="sticky-wrapper" [style.height]="utilsService.isSticky? utilsService.stickyHeight + 'px' : '' ">
        <div class="header-middle sticky-header" [class.fixed]="utilsService.isSticky">
            <div [class]="containerClass">
                <div class="header-left">
                    <molla-mobile-button></molla-mobile-button>

                    <a routerLink="/" class="logo">
                        <img src="assets/images/logo.png" class="bg-transparent" alt="Molla Logo" width="82"
                            height="22">
                    </a>
                  <molla-main-menu></molla-main-menu>
                </div>

                <div class="header-right">

                    <molla-header-search></molla-header-search>

                    <!-- <molla-wishlist-menu></molla-wishlist-menu> -->

                    <!-- <molla-cart-menu></molla-cart-menu> -->
                </div>
            </div>
        </div>
    </div>
</header>
