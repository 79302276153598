<div class="main">
  <div class="container">
    <div
      class="page-header page-header-big text-center"
      style="background-image: url('assets/images/about-header-bg.jpg')"
    >
      <h1 class="page-title text-white">
        About us<span class="text-white">Who we are</span>
      </h1>
    </div>
  </div>

  <div class="page-content pb-0">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mb-3 mb-lg-0">
          <h2 class="title">Thank you, before we say Hello.</h2>
          <p>
            Thank you for supporting our small business all the way from 2018,
            we are grateful that we were given a purpose and a dream to live.
          </p>
        </div>

        <div class="col-lg-6">
          <!-- <h2 class="title">Our Mission</h2> -->
          <p>
            Mintie Jamie was founded, with love, passion and beliefs.
            We strive to make good impacts to everyone around us & passing on the kindness that we received.
            <br />Unigue pieces are created to help people to create precious memories, nothing is too small to celebrate, isn't it?
          </p>
        </div>
      </div>

      <div class="mb-5"></div>
    </div>

    <div class="bg-light-2 pt-6 pb-5 mb-6 mb-lg-8 text-center">

      <div class="container">
        <div class="row">
          <!-- <div class="col-lg-5 mb-3 mb-lg-0"> -->
          <div class="col-lg-12 mb-3 mb-lg-0">

            <h2 class="title">Who We Are</h2>
            <p class="lead text-primary mb-3">
                We don't trade our purpose for profits.
                <br />
                We don't give up when it is hard to survive.
                 <br />
                 The grit and resilience we hold is the power for our next generation.
            </p>

            <!-- <a
              routerLink="/blog/classic"
              class="btn btn-sm btn-minwidth btn-outline-primary-2"
            >
              <span>VIEW OUR NEWS</span>
              <i class="icon-long-arrow-right"></i>
            </a> -->
          </div>

          <!-- <div class="col-lg-6 offset-lg-1"> -->
          <div class="col-lg-6">

            <!-- <div class="about-images"> -->
            <div>

                <!-- <img
                src="assets/images/about-header-bg.jpg"
                alt="About"
              /> -->
              <!-- <img
                src="assets/images/about/img-1.jpg"
                alt="About"
                class="about-img-front"
              />
              <img
                src="assets/images/about/img-2.jpg"
                alt="About"
                class="about-img-back"
              /> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-lg-5 mb-6">
          <div class="brands-text">
            <h2 class="title">
                And finally, Hello!
            </h2>
            <p>
                We are Mynn and Jameson, what's your name?
                <br>
                (Oh! Tyler saying Hi too!)
            </p>
          </div>
        </div>
        <div class="col-lg-7">
          <div class="brands-display">
            <div class="row justify-content-center">
                    <img
                    src="assets/images/about-us-1.jpg"
                    alt="About"
                  />
                  <br>
                  <br>
              <!-- <div class="col-6 col-sm-4" *ngFor="let brand of brands">
                <a href="javascript:;" class="brand">
                  <img [src]="brand.image" alt="Brand Name" />
                </a>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <!-- <hr class="mt-4 mb-6" />

      <h2 class="title text-center mb-4">Meet Our Team</h2>

      <owl-carousel [options]="teamOption">
        <div class="member member-anim text-center" *ngFor="let member of team">
          <figure class="member-media">
            <molla-image
              [src]="member.photo"
              width="376"
              height="500"
              alt="member photo"
            ></molla-image>

            <figcaption class="member-overlay">
              <div class="member-overlay-content">
                <h3 class="member-title">
                  {{ member.name }}<span>{{ member.title }}</span>
                </h3>
                <p>
                  Sed pretium, ligula sollicitudin viverra, tortor libero
                  sodales leo, eget blandit nunc.
                </p>
                <div class="social-icons social-icons-simple">
                  <a href="javascript:;" class="social-icon" title="Facebook"
                    ><i class="icon-facebook-f"></i
                  ></a>
                  <a href="javascript:;" class="social-icon" title="Twitter"
                    ><i class="icon-twitter"></i
                  ></a>
                  <a href="javascript:;" class="social-icon" title="Instagram"
                    ><i class="icon-instagram"></i
                  ></a>
                </div>
              </div>
            </figcaption>
          </figure>
          <div class="member-content">
            <h3 class="member-title">
              {{ member.name }}<span>{{ member.title }}</span>
            </h3>
          </div>
        </div>
      </owl-carousel> -->
    </div>

    <div class="mb-6"></div>

    <div
      class="about-testimonials bg-light-2 pt-6 pb-6"
      style="margin-bottom: -1px"
    >
      <div class="container">
        <h2 class="title text-center mb-3">What Customer Say About Us</h2>

        <owl-carousel
          [carouselClasses]="'owl-simple owl-testimonials-photo'"
          [options]="sliderOption"
        >
          <blockquote
            class="testimonial text-center"
            *ngFor="let custom of customSay"
          >
            <img [src]="custom.photo" width="70" height="70" alt="user" />
            <p>{{ custom.comment }}</p>
            <cite>
              {{ custom.name }}
              <span>{{ custom.type }}</span>
            </cite>
          </blockquote>
        </owl-carousel>
      </div>
    </div>
  </div>
</div>
