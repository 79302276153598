<main class="main">
    <molla-page-header title="Tabs" subtitle="Elements"></molla-page-header>

    <nav class="breadcrumb-nav">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a routerLink="/">Home</a>
                </li>
                <li class="breadcrumb-item">
                    <a routerLink="/elements">Elements</a>
                </li>
                <li class="breadcrumb-item active">Tabs</li>
            </ol>
        </div>
    </nav>

    <div class="page-content">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2 class="title mb-3">Left Align Style</h2>
                </div>
                <div class="col-md-6 mb-2">
                    <ul ngbNav #nav1="ngbNav" class="tab-content-border nav-tabs-bg nav-tabs">
                        <li ngbNavItem *ngFor="let content of horiData; let i = index">
                            <a ngbNavLink>Tab {{ i  + 1 }}</a>
                            <ng-template ngbNavContent>
                                <p>{{ content }}</p>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav1"></div>
                </div>

                <div class="col-md-6">
                    <ul ngbNav #nav2="ngbNav" class="tab-content-border nav nav-tabs">
                        <li ngbNavItem *ngFor="let content of horiData; let i = index">
                            <a ngbNavLink>Tab {{ i  + 1 }}</a>
                            <ng-template ngbNavContent>
                                <p>{{ content }}</p>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav2"></div>
                </div>
            </div>

            <hr class="mt-5 mb-4">

            <div class="row">
                <div class="col-12">
                    <h2 class="title mb-3">Centered Align Style</h2>
                </div>
                <div class="col-md-6 mb-2">
                    <ul ngbNav #nav3="ngbNav" class="tab-content-border nav-tabs-bg nav-tabs justify-content-center">
                        <li ngbNavItem *ngFor="let content of horiData; let i = index">
                            <a ngbNavLink>Tab {{ i  + 1 }}</a>
                            <ng-template ngbNavContent>
                                <p>{{ content }}</p>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav3"></div>
                </div>

                <div class="col-md-6">
                    <ul ngbNav #nav4="ngbNav" class="tab-content-border justify-content-center nav nav-tabs">
                        <li ngbNavItem *ngFor="let content of horiData; let i = index">
                            <a ngbNavLink>Tab {{ i  + 1 }}</a>
                            <ng-template ngbNavContent>
                                <p>{{ content }}</p>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav4"></div>
                </div>
            </div>

            <hr class="mt-5 mb-4">

            <div class="row">
                <div class="col-12">
                    <h2 class="title mb-3">Line Style Tabs</h2>
                </div>
                <div class="col-md-6 mb-2">
                    <ul ngbNav #nav5="ngbNav" class="nav nav-pills">
                        <li ngbNavItem *ngFor="let content of horiData; let i = index">
                            <a ngbNavLink>Tab {{ i  + 1 }}</a>
                            <ng-template ngbNavContent>
                                <p>{{ content }}</p>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav5"></div>
                </div>

                <div class="col-md-6">
                    <ul ngbNav #nav6="ngbNav" class="justify-content-center nav nav-pills">
                        <li ngbNavItem *ngFor="let content of horiData; let i = index">
                            <a ngbNavLink>Tab {{ i  + 1 }}</a>
                            <ng-template ngbNavContent>
                                <p>{{ content }}</p>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav6"></div>
                </div>
            </div>

            <hr class="mt-4 mb-4">

            <div class="row">
                <div class="col-12">
                    <h2 class="title mb-3">Vertical Style</h2>
                </div>
                <div class="col-md-6 mb-2 tabs-vertical">
                    <ul ngbNav #nav7="ngbNav" class="nav nav-tabs nav-tabs-bg" [orientation]="'vertical'">
                        <li ngbNavItem *ngFor="let content of vertData; let i = index">
                            <a ngbNavLink>Tab {{ i  + 1 }}</a>
                            <ng-template ngbNavContent>
                                <p>{{ content }}</p>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav7"></div>
                </div>

                <div class="col-md-6 tabs-vertical">
                    <ul ngbNav #nav8="ngbNav" class="nav-tabs nav" [orientation]="'vertical'">
                        <li ngbNavItem *ngFor="let content of vertData; let i = index">
                            <a ngbNavLink>Tab {{ i  + 1 }}</a>
                            <ng-template ngbNavContent>
                                <p>{{ content }}</p>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav8"></div>
                </div>
            </div>
        </div>
    </div>
</main>