<div class="main">
    <molla-page-header title="Product Category" subtitle="Elements"></molla-page-header>

    <!-- <nav class="breadcrumb-nav">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a routerLink="/">Home</a>
                </li>
                <li class="breadcrumb-item">
                    <a routerLink="/elements">Elements</a>
                </li>
                <li class="breadcrumb-item active">Product Category</li>
            </ol>
        </div>
    </nav> -->

    <div class="page-content">
        <div class="container">
            <h2 class="title text-center mb-3">2 Columns</h2>

            <div class="row">
                <div class="col-md-6">
                    <div class="banner banner-cat">
                        <a routerLink="/shop/sidebar/list">
                            <molla-image src="assets/images/category/banner-1.jpg" alt="Banner" width="570" height="280">
                            </molla-image>
                        </a>

                        <div class="banner-content">
                            <h3 class="banner-title">Women</h3>

                            <h4 class="banner-subtitle">18 Products</h4>

                            <a routerLink="/shop/sidebar/list" class="banner-link">Shop Now</a>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="banner banner-cat">
                        <a routerLink="/shop/sidebar/list">
                            <molla-image src="assets/images/category/banner-2.jpg" alt="Banner" width="570" height="280">
                            </molla-image>
                        </a>

                        <div class="banner-content">
                            <h3 class="banner-title">Men</h3>

                            <h4 class="banner-subtitle">12 Products</h4>

                            <a routerLink="/shop/sidebar/list" class="banner-link">Shop Now</a>
                        </div>
                    </div>
                </div>
            </div>

            <hr class="mb-4" />

            <h2 class="title text-center mb-3">3 Columns Badge Style</h2>

            <div class="row justify-content-center">
                <div class="col-md-6 col-lg-4">
                    <div class="banner banner-cat">
                        <a routerLink="/shop/sidebar/list">
                            <molla-image src="assets/images/category/3cols/banner-1.jpg" alt="Banner" width="370"
                                height="250"></molla-image>
                        </a>

                        <div class="banner-content banner-content-overlay text-center">
                            <h3 class="banner-title">Women</h3>

                            <h4 class="banner-subtitle">18 Products</h4>

                            <a routerLink="/shop/sidebar/list" class="banner-link">Shop Now</a>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-lg-4">
                    <div class="banner banner-cat">
                        <a routerLink="/shop/sidebar/list">
                            <molla-image src="assets/images/category/3cols/banner-2.jpg" alt="Banner" width="370"
                                height="250"></molla-image>
                        </a>

                        <div class="banner-content banner-content-overlay text-center">
                            <h3 class="banner-title">Men</h3>

                            <h4 class="banner-subtitle">12 Products</h4>

                            <a routerLink="/shop/sidebar/list" class="banner-link">Shop Now</a>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-lg-4">
                    <div class="banner banner-cat">
                        <a routerLink="/shop/sidebar/list">
                            <molla-image src="assets/images/category/3cols/banner-3.jpg" alt="Banner" width="370"
                                height="250"></molla-image>
                        </a>

                        <div class="banner-content banner-content-overlay text-center">
                            <h3 class="banner-title">Accessories</h3>

                            <h4 class="banner-subtitle">8 Products</h4>

                            <a routerLink="/shop/sidebar/list" class="banner-link">Shop Now</a>
                        </div>
                    </div>
                </div>
            </div>

            <hr class="mb-4" />

            <h2 class="title text-center mb-3">4 Columns Carousel</h2>

            <owl-carousel carouselClasses="owl-simple" data="bannerSlider" [options]='sliderOption'>
                <div class="banner banner-cat">
                    <a routerLink="/shop/sidebar/list">
                        <molla-image src="assets/images/category/4cols/banner-1.jpg" alt="Banner" width="270"
                            height="300"></molla-image>
                    </a>

                    <div class="banner-content banner-content-static text-center">
                        <h3 class="banner-title">Women</h3>

                        <h4 class="banner-subtitle">18 Products</h4>

                        <a routerLink="/shop/sidebar/list" class="banner-link">Shop Now</a>
                    </div>

                </div>

                <div class="banner banner-cat">
                    <a routerLink="/shop/sidebar/list">
                        <molla-image src="assets/images/category/4cols/banner-2.jpg" alt="Banner" width="270"
                            height="300"></molla-image>
                    </a>

                    <div class="banner-content banner-content-static text-center">
                        <h3 class="banner-title">Men</h3>

                        <h4 class="banner-subtitle">12 Products</h4>

                        <a routerLink="/shop/sidebar/list" class="banner-link">Shop Now</a>
                    </div>

                </div>

                <div class="banner banner-cat">
                    <a routerLink="/shop/sidebar/list">
                        <molla-image src="assets/images/category/4cols/banner-3.jpg" alt="Banner" width="270"
                            height="300"></molla-image>
                    </a>

                    <div class="banner-content banner-content-static text-center">
                        <h3 class="banner-title">Shoes & Boots</h3>

                        <h4 class="banner-subtitle">15 Products</h4>

                        <a routerLink="/shop/sidebar/list" class="banner-link">Shop Now</a>
                    </div>

                </div>

                <div class="banner banner-cat">
                    <a routerLink="/shop/sidebar/list">
                        <molla-image src="assets/images/category/4cols/banner-4.jpg" alt="Banner" width="270"
                            height="300"></molla-image>
                    </a>

                    <div class="banner-content banner-content-static text-center">
                        <h3 class="banner-title">Accessories</h3>

                        <h4 class="banner-subtitle">8 Products</h4>

                        <a routerLink="/shop/sidebar/list" class="banner-link">Shop Now</a>
                    </div>

                </div>

                <div class="banner banner-cat">
                    <a routerLink="/shop/sidebar/list">
                        <molla-image src="assets/images/category/4cols/banner-1.jpg" alt="Banner" width="270"
                            height="300"></molla-image>
                    </a>

                    <div class="banner-content banner-content-static text-center">
                        <h3 class="banner-title">Women</h3>

                        <h4 class="banner-subtitle">18 Products</h4>

                        <a routerLink="/shop/sidebar/list" class="banner-link">Shop Now</a>
                    </div>

                </div>
            </owl-carousel>

            <hr class="mb-4" />
        </div>

        <div class="container-fluid">
            <h2 class="title text-center mb-3">3 Columns Fullwidth</h2>

            <div class="row justify-content-center">
                <div class="col-md-6 col-lg-4">
                    <div class="banner banner-cat">
                        <a routerLink="/shop/sidebar/list">
                            <molla-image src="assets/images/category/fullwidth/banner-1.jpg" alt="Banner" width="600"
                                height="280"></molla-image>
                        </a>

                        <div class="banner-content">
                            <h3 class="banner-title">Women</h3>

                            <h4 class="banner-subtitle">18 Products</h4>

                            <a routerLink="/shop/sidebar/list" class="banner-link">Shop Now</a>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-lg-4">
                    <div class="banner banner-cat">
                        <a routerLink="/shop/sidebar/list">
                            <molla-image src="assets/images/category/fullwidth/banner-2.jpg" alt="Banner" width="600"
                                height="280"></molla-image>
                        </a>

                        <div class="banner-content">
                            <h3 class="banner-title">Men</h3>

                            <h4 class="banner-subtitle">12 Products</h4>

                            <a routerLink="/shop/sidebar/list" class="banner-link">Shop Now</a>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-lg-4">
                    <div class="banner banner-cat">
                        <a routerLink="/shop/sidebar/list">
                            <molla-image src="assets/images/category/fullwidth/banner-3.jpg" alt="Banner" width="600"
                                height="280"></molla-image>
                        </a>

                        <div class="banner-content">
                            <h3 class="banner-title">Accessories</h3>

                            <h4 class="banner-subtitle">12 Products</h4>

                            <a routerLink="/shop/sidebar/list" class="banner-link">Shop Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <hr class="mb-4" />

            <h2 class="title text-center mb-3">Masonry</h2>

            <div class="row justify-content-center">
                <div class="col-sm-6 col-lg-3">
                    <div class="banner banner-cat banner-link-anim banner-large">
                        <a routerLink="/shop/sidebar/list">
                            <molla-image src="assets/images/category/grid/banner-1.jpg" alt="Banner" width="270"
                                height="549" fixedPt="563px"></molla-image>
                        </a>

                        <div class="banner-content banner-content-bottom">
                            <h3 class="banner-title">Accessories</h3>

                            <h4 class="banner-subtitle">8 Products</h4>

                            <a routerLink="/shop/sidebar/list" class="banner-link">Shop Now</a>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3 order-lg-last">
                    <div class="banner banner-cat banner-link-anim banner-large">
                        <a routerLink="/shop/sidebar/list">
                            <molla-image src="assets/images/category/grid/banner-4.jpg" alt="Banner" width="270"
                                height="549" fixedPt="563px"></molla-image>
                        </a>

                        <div class="banner-content banner-content-top">
                            <h3 class="banner-title">Shoes & Boots</h3>

                            <h4 class="banner-subtitle">15 Products</h4>

                            <a routerLink="/shop/sidebar/list" class="banner-link">Shop Now</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-sm-6 col-lg-12">
                            <div class="banner banner-cat banner-link-anim">
                                <a routerLink="/shop/sidebar/list">
                                    <molla-image src="assets/images/category/grid/banner-2.jpg" alt="Banner" width="750"
                                        height="270" fixedPt="272px"></molla-image>
                                </a>

                                <div class="banner-content">
                                    <h3 class="banner-title">Women</h3>

                                    <h4 class="banner-subtitle">18 Products</h4>

                                    <a routerLink="/shop/sidebar/list" class="banner-link">Shop Now</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-12">
                            <div class="banner banner-cat banner-link-anim">
                                <a routerLink="/shop/sidebar/list">
                                    <molla-image src="assets/images/category/grid/banner-3.jpg" alt="Banner" width="750"
                                        height="270" fixedPt="272px"></molla-image>
                                </a>

                                <div class="banner-content">
                                    <h3 class="banner-title">Men</h3>

                                    <h4 class="banner-subtitle">12 Products</h4>

                                    <a routerLink="/shop/sidebar/list" class="banner-link">Shop Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr class="mb-4" />

            <h2 class="title text-center mb-3">Grid Badge Style</h2>

            <div class="row">
                <div class="col-md-6">
                    <div class="banner banner-cat banner-badge">
                        <a routerLink="/shop/sidebar/list">
                            <molla-image src="assets/images/category/grid2/banner-1.jpg" alt="Banner" width="575"
                                height="545" fixedPt="554px"></molla-image>
                        </a>

                        <a class="banner-link" routerLink="/shop/sidebar/list">
                            <h3 class="banner-title">Accessories</h3>

                            <h4 class="banner-subtitle">8 Products</h4>

                            <span class="banner-link-text">Shop Now</span>
                        </a>
                    </div>
                </div>

                <div class="col-md-6 d-flex flex-column">
                    <div class="banner banner-cat banner-badge">
                        <a routerLink="/shop/sidebar/list">
                            <molla-image src="assets/images/category/grid2/banner-2.jpg" alt="Banner" width="575"
                                height="260" fixedPt="267px"></molla-image>
                        </a>

                        <a class="banner-link" routerLink="/shop/sidebar/list">
                            <h3 class="banner-title">Women</h3>

                            <h4 class="banner-subtitle">15 Products</h4>

                            <span class="banner-link-text">Shop Now</span>
                        </a>
                    </div>

                    <div class="banner banner-cat banner-badge flex-grow-1">
                        <a routerLink="/shop/sidebar/list" class="h-100">
                            <molla-image src="assets/images/category/grid2/banner-3.jpg" alt="Banner" width="575"
                                height="260" class="h-100" fixedPt="267px"></molla-image>
                        </a>

                        <a class="banner-link" routerLink="/shop/sidebar/list">
                            <h3 class="banner-title">Men</h3>

                            <h4 class="banner-subtitle">12 Products</h4>

                            <span class="banner-link-text">Shop Now</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>