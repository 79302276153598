<main class="main home-page">
    <div class="intro-slider-container mb-4">
        <owl-carousel carouselClasses="intro-slider owl-simple owl-nav-inside owl-nav-inside" [options]="introSlider">
            <div class="intro-slide" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/mintiejamie-f0fe3.appspot.com/o/Sliding%20images%2Fcarousel_1.jpg?alt=media&token=97a650d6-c8a2-4c46-aa55-d2e2de47b501');">
                <div class="container intro-content" contentAnim aniName="fadeInUpShorter">
                    <h3 class="intro-subtitle text-primary">TOP PICKS</h3>
                    <h1 class="intro-title">Best Selling Products <br></h1>

                    <a routerLink= "/shop/sidebar/3cols" class="btn btn-outline-primary-2">
                        <span>DISCOVER MORE</span>
                        <i class="icon-long-arrow-right"></i>
                    </a>
                </div>
            </div>

            <div class="intro-slide" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/mintiejamie-f0fe3.appspot.com/o/Sliding%20images%2Fcarousel_2.jpg?alt=media&token=46efa3a3-ac09-4c7c-a508-244878c34f47');">
                <div class="container intro-content" contentAnim aniName="fadeInUpShorter">
                    <h3 class="intro-subtitle text-primary">MADE WITH LOVE</h3>
                    <h1 class="intro-title text-black">Exclusive Motif Design <br>Only Our Shop</h1>

                    <a routerLink="/shop/sidebar/3cols" class="btn btn-outline-primary-2 min-width-sm">
                        <span>SHOP NOW</span>
                        <i class="icon-long-arrow-right"></i>
                    </a>
                </div>
            </div>
        </owl-carousel>
    </div>

    <div class="container page-content skeleton-body skel-shop-products position-relative bg-white" [class.loaded]="loaded" style="z-index: 100;">


        <div class="products-container" *ngIf="products.length == 0 && loaded else productsBlock">
            <p class="no-results">No products matching your selection.</p>
        </div>
        <ng-template #productsBlock>
            <div class="products-container">
                <div class="row" *ngIf="!loaded else loadedBlock">
                    <div class="skel-pro col-6 col-md-4 col-lg-3" *ngFor="let item of [1,2,3,4,5,6,7,8]"></div>
                </div>
                <ng-template #loadedBlock>
                    <molla-isotope-grid [options]="isotopeOption" #isotope>
                        <div class="col-1 grid-sizer"></div>
                        <div [ngClass]="getProductCategory(product)" *ngFor="let product of displayProducts; trackBy : trackByFn">
                            <molla-product-twelve [product]="product"></molla-product-twelve>
                        </div>
                    </molla-isotope-grid>
                </ng-template>
            </div>
        </ng-template>
    </div>
    <!-- <div class="load-more-container text-center mt-0 mb-7">
        <a
            href="javascript:;"
            class="btn btn-outline-dark-3 btn-load-more"
            (click)="loadMore()"
            *ngIf="loadMoreLoading || products.length < totalCount"
        >
            <span>More products</span>
            <i class="icon-refresh" [class.load-more-rotating]="loadMoreLoading"></i>
        </a>
    </div> -->
</main>
