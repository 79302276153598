<main class="main">

    <div class="container">
        <div
          class="page-header page-header-big text-center"
          style="background-image: url('assets/images/faq-header.jpg')"
        >
          <h1 class="page-title text-white">
            Frequently Asked Questions<span class="text-white">F.A.Q</span>
          </h1>
        </div>
      </div>

    <div class="page-content">
        <div class="container">
            <div *ngFor="let faqs of faqGroups;let i = index">
                <h2 class="title text-center mb-3">{{ faqs.title }}</h2>

                <molla-accordion [adClass]="faqs.items[0].adClass" [accordionID]="'accordion' + (1 + i)">
                    <molla-card *ngFor="let card of faqs.items[0].cards; let isFirst = first"
                        [adClass]="faqs.items[0].cardAclass" [title]="card.title" [parent]="'accordion' + (1 + i)"
                        [show]="isFirst && ! i ? true : false">
                        {{ card.body }}
                    </molla-card>
                </molla-accordion>
            </div>
        </div>
    </div>

    <div class="cta cta-display bg-image pt-4 pb-4"
        style="background-image: url(assets/images/backgrounds/cta/bg-7.jpg);">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-9 col-xl-7">
                    <div class="row no-gutters flex-sm-row align-items-sm-center">
                        <div class="col-12 col-sm">
                            <h3 class="cta-title text-white">If You Have More Questions</h3>
                            <!-- <p class="cta-desc text-white">Quisque volutpat mattis eros</p> -->
                        </div>

                        <div class="col-auto">
                            <a routerLink="/pages/contact" class="btn btn-outline-white"><span>CONTACT US</span><i
                                    class="icon-long-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>