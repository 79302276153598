import { Component, OnInit } from '@angular/core';

@Component( {
	selector: 'molla-category-menu',
	templateUrl: './category-menu.component.html',
	styleUrls: [ './category-menu.component.scss' ]
} )

export class CategoryMenuComponent implements OnInit {

	constructor () { }

	ngOnInit (): void {
	}
}
