<div class="main">
    <molla-page-header [title]="'Buttons'" [subtitle]="'Elements'"></molla-page-header>

    <nav class="breadcrumb-nav">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a routerLink="/">Home</a>
                </li>
                <li class="breadcrumb-item">
                    <a routerLink="/elements">Elements</a>
                </li>
                <li class="breadcrumb-item active">Buttons</li>
            </ol>
        </div>
    </nav>

    <div class="page-content">
        <div class="container">
            <h2 class="title">Default Style</h2>

            <div class="row">
                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Rounded Corners Style</span>
                        <a href="javascript:;" class="btn btn-primary btn-rounded">Button text</a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Square Style</span>
                        <a href="javascript:;" class="btn btn-primary">Button text</a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Round Style</span>
                        <a href="javascript:;" class="btn btn-primary btn-round">Button text</a>
                    </div>
                </div>
            </div>

            <hr class="mt-3 mb-4">

            <h2 class="title">Border Style</h2>

            <div class="row">
                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Rounded Corners Style</span>
                        <a href="javascript:;" class="btn btn-outline-primary btn-rounded">Button text</a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Square Style</span>
                        <a href="javascript:;" class="btn btn-outline-primary">Button text</a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Round Style</span>
                        <a href="javascript:;" class="btn btn-outline-primary btn-round">Button text</a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Rounded Corners Style</span>
                        <a href="javascript:;" class="btn btn-outline-dark btn-rounded">Button text</a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Square Style</span>
                        <a href="javascript:;" class="btn btn-outline-dark">Button text</a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Round Style</span>
                        <a href="javascript:;" class="btn btn-outline-dark btn-round">Button text</a>
                    </div>
                </div>
            </div>

            <hr class="mt-3 mb-4">
            <h2 class="title">Button With Icon Style</h2>

            <div class="row">
                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Rounded Corners Style</span>
                        <a href="javascript:;" class="btn btn-primary btn-rounded"><span>Button text</span><i
                                class="icon-long-arrow-right"></i></a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Square Style</span>
                        <a href="javascript:;" class="btn btn-primary"><span>Button text</span><i
                                class="icon-long-arrow-right"></i></a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Round Style</span>
                        <a href="javascript:;" class="btn btn-primary btn-round"><span>Button text</span><i
                                class="icon-long-arrow-right"></i></a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Rounded Corners Style</span>
                        <a href="javascript:;" class="btn btn-primary btn-rounded"><i
                                class="icon-long-arrow-right"></i><span>Button text</span></a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Square Style</span>
                        <a href="javascript:;" class="btn btn-primary"><i class="icon-long-arrow-right"></i><span>Button
                                text</span></a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Round Style</span>
                        <a href="javascript:;" class="btn btn-primary btn-round"><i
                                class="icon-long-arrow-right"></i><span>Button text</span></a>
                    </div>
                </div>
            </div>

            <hr class="mt-3 mb-4">

            <h2 class="title">Border Style With Icons</h2>

            <div class="row">
                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Rounded Corners Style</span>
                        <a href="javascript:;" class="btn btn-outline-primary btn-rounded"><span>Button text</span><i
                                class="icon-long-arrow-right"></i></a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Square Style</span>
                        <a href="javascript:;" class="btn btn-outline-primary"><span>Button text</span><i
                                class="icon-long-arrow-right"></i></a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Round Style</span>
                        <a href="javascript:;" class="btn btn-outline-primary btn-round"><span>Button text</span><i
                                class="icon-long-arrow-right"></i></a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Rounded Corners Style</span>
                        <a href="javascript:;" class="btn btn-outline-primary btn-rounded"><i
                                class="icon-long-arrow-right"></i><span>Button text</span></a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Square Style</span>
                        <a href="javascript:;" class="btn btn-outline-primary"><i
                                class="icon-long-arrow-right"></i><span>Button text</span></a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Round Style</span>
                        <a href="javascript:;" class="btn btn-outline-primary btn-round"><i
                                class="icon-long-arrow-right"></i><span>Button text</span></a>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Rounded Corners Style</span>
                        <a href="javascript:;" class="btn btn-outline-dark btn-rounded"><span>Button text</span><i
                                class="icon-long-arrow-right"></i></a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Square Style</span>
                        <a href="javascript:;" class="btn btn-outline-dark"><span>Button text</span><i
                                class="icon-long-arrow-right"></i></a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Round Style</span>
                        <a href="javascript:;" class="btn btn-outline-dark btn-round"><span>Button text</span><i
                                class="icon-long-arrow-right"></i></a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Rounded Corners Style</span>
                        <a href="javascript:;" class="btn btn-outline-dark btn-rounded"><i
                                class="icon-long-arrow-right"></i><span>Button text</span></a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Square Style</span>
                        <a href="javascript:;" class="btn btn-outline-dark"><i
                                class="icon-long-arrow-right"></i><span>Button text</span></a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Round Style</span>
                        <a href="javascript:;" class="btn btn-outline-dark btn-round"><i
                                class="icon-long-arrow-right"></i><span>Button text</span></a>
                    </div>
                </div>
            </div>

            <hr class="mt-3 mb-4">

            <h2 class="title">Buttons With Shadow Style</h2>

            <div class="row">
                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Rounded Corners Style</span>
                        <a href="javascript:;" class="btn btn-primary btn-rounded btn-shadow">Button text</a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Square Style</span>
                        <a href="javascript:;" class="btn btn-primary btn-shadow">Button text</a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Round Style</span>
                        <a href="javascript:;" class="btn btn-primary btn-round btn-shadow">Button text</a>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Rounded Corners Style</span>
                        <a href="javascript:;" class="btn btn-primary btn-rounded btn-shadow"><span>Button text</span><i
                                class="icon-long-arrow-right"></i></a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Square Style</span>
                        <a href="javascript:;" class="btn btn-primary btn-shadow"><span>Button text</span><i
                                class="icon-long-arrow-right"></i></a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Round Style</span>
                        <a href="javascript:;" class="btn btn-primary btn-round btn-shadow"><span>Button text</span><i
                                class="icon-long-arrow-right"></i></a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Rounded Corners Style</span>
                        <a href="javascript:;" class="btn btn-primary btn-rounded btn-shadow"><i
                                class="icon-long-arrow-right"></i><span>Button text</span></a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Square Style</span>
                        <a href="javascript:;" class="btn btn-primary btn-shadow"><i
                                class="icon-long-arrow-right"></i><span>Button text</span></a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Round Style</span>
                        <a href="javascript:;" class="btn btn-primary btn-round btn-shadow"><i
                                class="icon-long-arrow-right"></i><span>Button text</span></a>
                    </div>
                </div>
            </div>

            <hr class="mt-3 mb-4">

            <h2 class="title">Simple Button Styles</h2>

            <div class="row">
                <div class="col-12">
                    <a href="javascript:;" class="btn btn-link">Button Text</a>
                    <a href="javascript:;" class="btn btn-link"><span>Button Text</span><i
                            class="icon-long-arrow-right"></i></a>
                    <a href="javascript:;" class="btn btn-link"><i class="icon-long-arrow-right"></i><span>Button
                            Text</span></a>

                    <a href="javascript:;" class="btn btn-link btn-link-dark">Button Text</a>
                    <a href="javascript:;" class="btn btn-link btn-link-dark"><span>Button Text</span><i
                            class="icon-long-arrow-right"></i></a>
                    <a href="javascript:;" class="btn btn-link btn-link-dark"><i
                            class="icon-long-arrow-right"></i><span>Button Text</span></a>
                </div>
            </div>

            <hr class="mt-3 mb-1">
        </div>
    </div>

    <div class="container">
        <h2 class="title mb-3">Button on Backgrounds</h2>
    </div>

    <div class="bg-image bg-overlay pt-5 pb-6 mb-5 bg-parallax"
        style="background-image: url(assets/images/backgrounds/bg-large.jpg)" bgParallax>
        <div class="container">
            <div class="row">
                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Rounded Corners Style</span>
                        <a href="javascript:;" class="btn btn-primary btn-rounded">Button text</a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Square Style</span>
                        <a href="javascript:;" class="btn btn-primary">Button text</a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Round Style</span>
                        <a href="javascript:;" class="btn btn-primary btn-round">Button text</a>
                    </div>
                </div>
            </div>

            <div class="mb-2"></div>

            <div class="row">
                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Rounded Corners Style</span>
                        <a href="javascript:;" class="btn btn-outline-primary btn-rounded">Button text</a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Square Style</span>
                        <a href="javascript:;" class="btn btn-outline-primary">Button text</a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Round Style</span>
                        <a href="javascript:;" class="btn btn-outline-primary btn-round">Button text</a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Rounded Corners Style</span>
                        <a href="javascript:;" class="btn btn-outline-dark btn-rounded">Button text</a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Square Style</span>
                        <a href="javascript:;" class="btn btn-outline-dark">Button text</a>
                    </div>
                </div>

                <div class="col-6 col-lg-4 col-xl-2">
                    <div class="btn-wrap">
                        <span>Round Style</span>
                        <a href="javascript:;" class="btn btn-outline-dark btn-round">Button text</a>
                    </div>
                </div>
            </div>

            <div class="mb-2"></div>

            <div class="row">
                <div class="col-12">
                    <a href="javascript:;" class="btn btn-link">Button Text</a>
                    <a href="javascript:;" class="btn btn-link"><span>Button Text</span><i
                            class="icon-long-arrow-right"></i></a>
                    <a href="javascript:;" class="btn btn-link"><i class="icon-long-arrow-right"></i><span>Button
                            Text</span></a>

                    <a href="javascript:;" class="btn btn-link btn-link-dark">Button Text</a>
                    <a href="javascript:;" class="btn btn-link btn-link-dark"><span>Button Text</span><i
                            class="icon-long-arrow-right"></i></a>
                    <a href="javascript:;" class="btn btn-link btn-link-dark"><i
                            class="icon-long-arrow-right"></i><span>Button Text</span></a>
                </div>
            </div>
        </div>
    </div>
</div>